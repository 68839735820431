<template>
  <div class="information">
    <p class="title">必要信息填写</p>
    <div class="titleExplain">
      <img src="~assets/informationTop.png" alt="">
      <p class="explain">用于身份核验，您的身份详细将被严格保密</p>
    </div>
    <van-form class="informationForm">
      <van-field
        v-model="order.receiverName"
        name="姓名"
        label="姓名:"
        placeholder="请输入身份证姓名"
      />
      <van-field
        v-model="order.identityCardNum"
        name="身份证号码"
        label="身份证号码:"
        placeholder="请输入身份证号码"
        maxlength="18"
      />
      <van-field
        v-model="order.receiverMobilephone"
        name="收件手机号"
        label="收件手机号:"
        placeholder="请输入快手收件手机号"
        maxlength="11"
      />
    </van-form>
    <van-button type="info" @click="informationClick" class="informationButton" v-if="BtnStatus">提交</van-button>
    <van-button type="info" class="informationButton" v-else>提交中，请勿重复点击</van-button>
    <div class="bottomExplain">
      <img src="~assets/information.png" alt="" class="explainImg">
      <p class="explain">严格保障您的隐私安全</p>
    </div>
  </div>
</template>

<script>
import { updateIdCard } from 'api/api'
export default {
  name: 'information',
  data () {
    return {
      order: {
        receiverName: '',
        receiverMobilephone: '',
        identityCardNum: '',
        id: ''
      },
      BtnStatus: true
    }
  },
  created () {
    if (window.location.href.indexOf('?id=') > 0) {
      this.order.id = window.location.href.split('?id=')[1]
    }
  },
  methods: {
    checkIdCard () {
      var code = this.order.identityCardNum;
      var city= { 11:"北京",12:"天津",13:"河北",14:"山西",15:"内蒙古",21:"辽宁",22:"吉林",23:"黑龙江 ",31:"上海",32:"江苏",33:"浙江",34:"安徽",35:"福建",36:"江西",37:"山东",41:"河南",42:"湖北 ",43:"湖南",44:"广东",45:"广西",46:"海南",50:"重庆",51:"四川",52:"贵州",53:"云南",54:"西藏 ",61:"陕西",62:"甘肃",63:"青海",64:"宁夏",65:"新疆",71:"台湾",81:"香港",82:"澳门",91:"国外 "}        
      var tip = ""
      var pass= true
      if(!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|[xX])$/.test(code)){
        tip = "身份证号格式错误"
        pass = false
      } else if(!city[code.substr(0,2)]){                
        tip = "地址编码错误"
        pass = false
      } else {
      //18位身份证需要验证最后一位校验位
        if(code.length == 18){
          code = code.split('');
          //∑(ai×Wi)(mod 11)
          //加权因子
          var factor = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ];
          //校验位
          var parity = [ 1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2 ];
          var sum = 0;
          var ai = 0;
          var wi = 0;
          for (var i = 0; i < 17; i++){
            ai = code[i];
            wi = factor[i];
            sum += ai * wi;
          }
          var last = parity[sum % 11]         
          if(parity[sum % 11] != code[17]){   
            tip = "校验位错误"
            pass = false             
          }             
        }
      }
      console.log('身份证号', tip)
      return pass
    },
    informationClick () {
      if (!this.order.receiverName) {
        return this.$toast('请输入身份证姓名')
      }
      if (!(/^1[3456789]\d{9}$/.test(this.order.receiverMobilephone))) {
        return this.$toast('请输入正确的11位手机号')
      }
      if (!this.checkIdCard()) {
        return this.$toast('请输入正确的身份证号码')
      }
      this.BtnStatus = false
      updateIdCard(this.order).then(res => {
        this.BtnStatus = true
        if (res && res.code === 200) {
          this.$toast('成功')
        } else {
          this.$toast(res.message)
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  >>> .van-field
    display flex
    flex-direction column
  >>> .van-cell__title
    font-size 14px
    font-weight bold
    color #333
  .information
    background-color #fff
    padding 13px
    height 100%
    margin-top 30px
    .title
      font-size 16px
      font-weight 500
      color #4d4d4d
    .titleExplain
      display flex
      align-items center
      margin-top 12px
      background-color #ECFDFC
      border-radius 50px
      .explain
        width 90%
        background-color #ECFDFC
        font-size 10px
        font-weight 500
        color #388579
        padding 3px 10px
    .informationForm
      margin-top 34px
    .informationButton
      width 100%
      border-radius 7px
      margin-top 50px
    .bottomExplain
      display flex
      justify-content center
      align-items center
      position fixed
      bottom 20px
      left 0
      right 0
      margin-left auto
      margin-right auto
      .explain
        font-size 10px
        font-weight 500
        color #999
      .explainImg
        width 8px
        height 10px
        padding-right 5px
</style>